<template>
  <b-dialog v-model="visible" width="800px" :title="windowTitle">
    <el-form ref="form" class="" :model="formData" :rules="formRules" label-position="top">

      <el-form-item label="标题：" prop="title">
        <el-input v-model="formData.title" autocomplete show-word-limit maxlength="64" placeholder="输入标题。"></el-input>
      </el-form-item>
      <el-form-item label="分类标签：">
        <el-select v-model="formData.labels" multiple filterable allow-create default-first-option
          placeholder="选择或者输入新标签。">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容：" prop="content">
        <!-- <el-input v-model="formData.content" autocomplete maxlength="3000" show-word-limit type="textarea"
          :autosize="{ minRows: 6 }" placeholder="输入内容，严禁上传涉密信息。"></el-input>
 -->
          <EditorTextbus
            v-model="formData.content"
            v-if="visible"
            autocomplete
          ></EditorTextbus>

      </el-form-item>


      <el-form-item label="图片：" >
        <c-upload-image v-model="formData.images"></c-upload-image>
      </el-form-item>
      <el-form-item label="附件：" >
        <c-upload-media v-model="formData.media_keys" :default="defaultMedia"></c-upload-media>
      </el-form-item>

    <el-divider>所属类别：</el-divider>

    <el-form-item label="">
        <el-radio-group v-model="formData.column_id">
          <template v-for="item in allColumn">
            <el-radio :label="item.id" v-if="deptId==29 && item.name=='纪检监察意见'" :key="item.id">{{item.name}}</el-radio>
            <el-radio :label="item.id" :key="item.id" v-else-if="item.name!='纪检监察意见'">{{item.name}}</el-radio>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="关联工作计划:" v-if="formData.column_id===1">
        <JihuaAutocomplete @parentSelect="parentSelect" :parentId="formData.parent_id" :columnsId='2' :specialId='specialId'></JihuaAutocomplete>
      </el-form-item>
      <el-form-item  label="当前进度(非必填):" v-if="formData.column_id===1 && value1Show==true">
        <el-slider :step="5" show-stops v-model="formData.extra.progress"></el-slider>
      </el-form-item>
      <el-form-item label="自定义创建时间：" v-if="formData.column_id===1">
        <el-date-picker v-model="formData.created_at" type="datetime" placeholder="选择自定义创建时间">
        </el-date-picker>
      </el-form-item>


     <el-form-item label="开始日期" v-if="formData.column_id===2">
        <el-date-picker v-if="formData.extra" v-model="formData.extra.start_date" type="date" placeholder="选择开始时间日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期" v-if="formData.column_id===2">
        <el-date-picker v-if="formData.extra"  v-model="formData.extra.end_date" type="date" placeholder="选择开始结束日期">
        </el-date-picker>
      </el-form-item>

      <div style="margin-top: 20px; text-align: right;">
        <b-btn-confirm :loading="submitLoading" @click="onSubmit"></b-btn-confirm>
      </div>
    </el-form>
  </b-dialog>
</template>

<script>
  import CUploadImage from '@/components/upload/image'
  import CUploadMedia from '@/components/upload/media'
  import JihuaAutocomplete from './jihuaAutocomplete.vue'
  import EditorTextbus from '@/components/editor/textbus'

  import {
    getAllLabel,
    createSpecialAction,
    updateSpecialAction,
    getAllColumn
  } from '@/api/special'
 var myDate = new Date;
  export default {
    components: {
      CUploadImage,
      EditorTextbus,
      CUploadMedia,
      JihuaAutocomplete
    },
    props:['specialId'],
    data() {
      return {
        value1:0,
        value1Show:false,
        visible: false,
        submitLoading: false,
        defaultMedia: [],
        formData: {
          created_at: '',
          extra:{
            progress:0,
            start_date:'2022-05-01',
            end_date:'2022-05-01',
          },
          title:"",
          content:"",
        },
        formRules: {
          content: [{
            required: true,
            message: '请填写内容',
            trigger: 'blur',
          }, ],
          title: [{
            required: true,
            message: '标题必填项',
            trigger: 'blur',
          }, ],
        },
        options: [],
        value: [],
        windowTitle: '',
        allColumn: [],
        restaurants: [],
        state: '',
        timeout: null,
      }
    },
    computed: {
      deptId() {
        return this.$store.getters['user/deptId']
      },
    },
    methods: {
      parentSelect(e){
        this.formData.parent_id=e.id
      //  this.value1Show=true
      },
      open(val, columnName) {
        // console.log(JSON.stringify(val))
        this.windowTitle = columnName
        this.formData = val
        this.defaultMedia = val.media_keys
        this.formData.media_keys = []
        this.formData.label_ids = []

        this.formData.extra = {
            progress:0,
            start_date:'',
            end_date:'',
          }
        this.visible = true
      },
      getTags(userId, departmentId) {
        this.loading = true
        getAllLabel({
            user_id: userId,
            department_id: departmentId,
            role: 2,
            page: 1,
            per_page: 30,
          })
          .then(res => {
            this.options = res
          })
          .finally(() => {
            this.loading = false
          })
        getAllColumn()
          .then(res => {
            this.allColumn = res
          })
          .finally(() => {
            this.loading = false
          })
      },
      onSubmit() {
       if(!this.formData.title || this.formData.title==""){
          this.$message.error('文章标题必须填写');
          return false;
       }
       
       if(!this.formData.content || this.formData.content==""|| this.formData.content=="<p><br></p>" ||this.formData.content=="<p></p>"){

          this.$message.error('文章内容必须填写');
          return false;
       }


        if (this.formData && this.formData.labels && this.formData.labels.length > 0) {
          this.formData.label_ids = this.formData.labels
        }
        console.log(JSON.stringify(this.formData))
        if (true) {
          this.submitLoading = true
          if (this.formData.id) {
            updateSpecialAction(this.formData.id, this.formData)
              .then(res => {
                this.$message.success('修改成功')
                this.$emit('success')
                this.visible = false
                setTimeout(() => {
                  location.reload()
                }, 1200)
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            createSpecialAction(this.formData)
              .then(res => {
                this.$message.success('新建成功')
                this.$emit('success')
                this.visible = false
                setTimeout(() => {
                  location.reload()
                }, 1200)
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        }
      },
    },
  }
</script>
