<template>
  <b-dialog
    width="600px"
    v-model="visible"
    title="回复"
  >
    <el-form
      style="display: flex; align-items: center; margin-bottom: 15px;"
      ref="form"
      :model="formData"
      :rules="formRules"
    >
      <el-form-item
        style="flex: 1; margin: 0; margin-right: 10px;"
        prop="content"
      >
        <el-input
          v-model="formData.content"
          placeholder="输入内容"
        ></el-input>
      </el-form-item>
      <b-btn-confirm
        :loading="submitLoading"
        @click="onSubmit"
      >回复</b-btn-confirm>
    </el-form>

    <div
      v-loading="loading"
      style="max-height: 500px; padding: 10px; overflow-y: auto;"
    >
      <div
        class="g-empty"
        v-if="list.length == 0"
      ></div>

      <div
        class="list-item"
        v-for="item in list"
        :key="item.id"
      >
        <div class="list-header">
          <el-avatar
            size="small"
            :src="item.user.avatar"
          ></el-avatar>
          <span class="username">{{ item.user.name }}</span>
          <span class="time">回复于 {{ item.created_at}}</span>
          <el-button
            style="margin-left: auto;"
            v-if="item.user_id == userId"
            icon="el-icon-delete"
            type="text"
            @click="onDelete(item)"
          >删除</el-button>
          <!-- <el-button
            v-if="item.user_id == userId"
            icon="el-icon-edit"
            type="text"
            @click="onUpdate(item)"
          >编辑</el-button> -->
        </div>
        <div
          class="list-content"
          v-html="item.content"
        ></div>
      </div>
    </div>

    <b-pagination
      :page="page"
      @change="fetchList"
    ></b-pagination>

  </b-dialog>
</template>

<script>
import {
  getActionCommentList,
  createActionComment,
  // updateActionComment,
  deleteActionComment,
} from '@/api/special'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      actionId: null,
      submitLoading: false,
      list: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      formData: {
        content: '',
      },
      formRules: {
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }],
      },
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
  },
  methods: {
    open(id) {
      this.actionId = id
      this.formData.content = ''
      this.list = []
      this.fetchList()
      this.visible = true
    },
    fetchList() {
      const params = {
        page: this.page.current,
        special_content_id: this.actionId,
      }
      this.loading = true
      getActionCommentList(params)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          const params = {
            special_content_id: this.actionId,
            content: this.formData.content,
          }
          createActionComment(params)
            .then(res => {
              this.formData.content = ''
              this.onRefresh()
              this.$message.success('新建成功')
            })
            .finally(() => {
              this.submitLoading = false
            })
          // if (this.formData.id) {
          //   updateSpecialAction(this.formData.id, this.formData)
          //     .then(res => {
          //       this.$message.success('修改成功')
          //       this.$emit('success')
          //       this.visible = false
          //     })
          //     .finally(() => {
          //       this.submitLoading = false
          //     })
          // } else {

          // }
        }
      })
    },
    onDelete(item) {
      this.$confirm('确定删除当前项吗？', '提示', { type: 'warning' })
        .then(() => {
          return deleteActionComment(item.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefresh()
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.list-item {
  padding: 10px 20px;
  margin-bottom: 10px;
  background-color: rgba($color: #000, $alpha: 0.05);
  border-radius: 4px;

  .list-header {
    display: flex;
    align-items: center;

    .username {
      margin-right: 20px;
      margin-left: 10px;
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }
  }

  .list-content {
    padding: 5px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #888;
    word-break: break-all;
  }
}
</style>
