<template>
  <el-autocomplete v-model="state" style="width: 100%;" :fetch-suggestions="querySearchAsync"
    placeholder="输入关键词,搜索本专题工作计划" @select="handleSelect">
  </el-autocomplete>

</template>
<script>
  import {
    getAllColumn,
    getSpecialActionPage,
    deleteSpecialAction,
    getActionFabulousUsers,
    getActionFabulousUsersStatus,
    updateActionFabulousUsers,
  } from '@/api/special/index'

  export default {
    data() {
      return {
        restaurants: [],
        state: '',
        timeout: null,

      };
    },
    props: ['columnsId', 'specialId', 'parentId'],
    methods: {
      loadAll() {
        const that = this
        const params = {
          column_id: this.columnsId,
          special_id: this.specialId,
          page: 1,
          per_page: 50,
        }
        const _key = Date.now()
        getSpecialActionPage(params).then(res => {
          if (!res.data) {
            return
          }
          
          that.restaurants = res.data.map((item) => {
            if (that.parentId == item.id) {
         
              that.state = item.title
            }

            return {
              value: item.title,
              id: item.id
            }
          })
        })

        // return [
        //   {
        //     "value": "南拳妈妈龙虾盖浇饭",
        //     "address": "普陀区金沙江路1699号鑫乐惠美食广场A13"
        //   }
        // ];
      },
      querySearchAsync(queryString, cb) {

        var restaurants = this.restaurants;

        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        cb(results);
      },
      createStateFilter(queryString) {

        return (state) => {
          return state.value.toLowerCase().match(queryString.toLowerCase());
        };
      },
      handleSelect(item) {
        this.$emit("parentSelect", item)

      }
    },
    mounted() {
      this.loadAll();
    }
  };
</script>
