<!--
 * @Author: jiang
 * @Date: 2021-06-13 12:00:22
 * @Description: 图片列表
-->

<template>
  <div class="c-image-list">
    <el-image
      class="c-image-item"
      :style="{ width: width + 'px', height: width + 'px' }"
      fit="cover"
      v-for="item in list"
      :key="item"
      :src="item"
      :preview-src-list="list"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 140,
    },
  },
}
</script>

<style lang="scss" scoped>
.c-image-list {
  display: flex;
  flex-wrap: wrap;

  .c-image-item {
    margin: 5px;
  }
}
</style>
