<template>
  <!-- 落实列表 -->
  <div class="list">
    <div style="margin-bottom: 16px; margin-left: 10px; text-align: center;">
       <b-btn-create @click="onCreate" v-if="deptId==29 && columnName=='纪检监察意见'">填写{{columnName}}</b-btn-create>
       <b-btn-create @click="onCreate" v-else-if="columnName!='纪检监察意见'">填写{{columnName}}</b-btn-create>

    </div>
    <div class="g-empty" v-if="actionList.length === 0"></div>
    <div v-else>

     <!-- <b-pagination :page="page" @change="fetchActionList" style="margin-bottom: 20px; text-align: left !important;" ></b-pagination> -->
    </div>
    <div class="item-box" :style="{ height: boxHeight + 'px' }">
      <div
        class="item-position"
        v-for="item in actionList"
        :key="item._key"
        :style="{ top: item._top + 'px', left: item._left + 'px' }"
      >
        <div class="g-bg item" ref="item">
          <div class="header">
              <div class="title"><h3>{{item.title}}</h3> </div>
            <div class="time2">
              <el-tag class="g-mr-5 g-mb-5" v-for="(label,i) in item.labels" :key="i" type="info">
                {{label.name}} </el-tag>
            </div>
            <div>
              <el-button
                v-if="item.user_id == userId || isDeptAdmin"
                icon="el-icon-delete"
                type="danger"
                size="mini"
                @click="onDelete(item)"
              ></el-button>
              <el-button
                v-if="item.user_id == userId || isDeptAdmin"
                icon="el-icon-edit"
                type="primary"
                size="mini"
                @click="onUpdate(item)"
              ></el-button>
            </div>
          </div>
          <div class="info">
            <div class="content" v-html="getHtml(item.detail.detail)">

              <!-- <el-input :value="item.detail.detail" type="textarea"> -->
            </div>
            <div class="images" v-if="item.images && item.images.length > 0">
              <c-image-list :list="item.images"></c-image-list>
            </div>
            <div class="media" v-if="item.media && item.media.length > 0">
              <c-media-list :list="item.media"></c-media-list>
            </div>

            <div class="footer">
              <div class="user">
                <span class="g-mr-10" v-if="item.department">{{ item.department.name }}</span>
                <span class="g-mr-10" v-if="item.user">{{ item.user.name }}</span>
                <span  style="font-size: 14px; color: #ababab;">{{ item.created_at }}</span>
              </div>

            </div>
          </div>
          <div style="margin-top: 10px;" v-if="item._users && item._users.length">
            <img class="praise-icon" src="@/assets/icons/praise-red.png" />
            <span
              style="margin-right: 8px; font-size: 14px; font-weight: 600; line-height: 20px;"
              v-for="user in item._users"
              :key="user.id"
              >{{ user.name }}</span
            >
          </div>
          <div class="action" style="margin-top: 10px; text-align: center;">
            <el-button
              style="width: 80px; height: 34px;"
              @click="onFabulous(item)"
              :type="item._fabulous ? 'danger' : 'default'"
            >
              <img v-if="item._fabulous" class="praise-icon" src="@/assets/icons/praise-white.png" />
              <img v-else class="praise-icon" src="@/assets/icons/praise-grey.png" />
              <span>赞</span>
            </el-button>
            <el-button
              type="default"
              style="height: 34px;"
              @click="onOpenComment(item)"
              icon="el-icon-chat-dot-round"
              >回复 <span v-if="item.comment_count" style="color: red;">（{{item.comment_count}}）</span></el-button>
          </div>
        </div>
      </div>
    </div>

    <comment-detail ref="comment"></comment-detail>
    <FormActionDetail :specialId="id" ref="actionDetail"></FormActionDetail>
    <b-pagination :page="page" @change="fetchActionList" style="margin: 20px;" ></b-pagination>
  </div>
</template>

<script>
import FormActionDetail from '../form-action-detail.vue'
import CImageList from '@/components/upload/image-list'
import CMediaList from '@/components/upload/media-list'
import CommentDetail from '../comment-list.vue'

import {
  getAllColumn,
  getSpecialActionPage,
  deleteSpecialAction,
  getActionFabulousUsers,
  getActionFabulousUsersStatus,
  updateActionFabulousUsers,
} from '@/api/special/index'

export default {
  components: {
    FormActionDetail,
    CImageList,
    CMediaList,
    CommentDetail,
  },
  props: [
    'columnsId',
    'specialId',
    'columnName',
  ],
  data() {
    return {
      praiseWhiteUrl: require('@/assets/icons/praise-white.png'),
      praiseGreyUrl: require('@/assets/icons/praise-grey.png'),
      id: null,
      task_id: null,
      loading: false,
      info: {},
      actionList: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      boxHeight: 0,
      allColumn: [],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
    isDeptAdmin() {
      return this.$store.getters['user/isDeptAdmin']
    },
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        this.id = id || this.specialId
        this.task_id = this.$route.query.task_id
        // this.fetchData()
        this.fetchActionList()
        // this.getTags()
      },
      immediate: true,
    },
  },
  methods: {
    getHtml(htmlStr){
       var  reg = /<[^>]+>/g;
       if(reg.test(htmlStr)){
         return htmlStr;
       }else{
         return "<p style='text-indent: 2em;'>"+htmlStr+"</p>";
       }
    },
    fetchData() {
      this.loading = true
      getAllColumn()
        .then(res => {
          this.allColumn = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchActionList() {
      const that = this
      const params = {
        column_id: this.columnsId,
        special_id: this.id,
        task_id: this.task_id,
        page: this.page.current,
      }
      const _key = Date.now()
      getSpecialActionPage(params).then(res => {
        if (!res.data) { return }
        res.data.forEach(item => {
          item._key = item.id + '_' + _key
          that.handlerGetFabulous(item)
          that.handlerGetUsers(item)
        })
        that.actionList = res.data
        that.page.total = res.total

        that.handlerRender()
      })
    },
    handlerGetFabulous(item) {
      getActionFabulousUsersStatus(item.id).then(ret => {
        this.$set(item, '_fabulous', ret.state)
        this.handlerRender()
      })
    },
    handlerGetUsers(item) {
      getActionFabulousUsers(item.id).then(res => {
        this.$set(item, '_users', res)
        this.handlerRender()
      })
    },
    onRefreshList() {
      this.page.current = 1
      this.fetchActionList()
    },
    handlerRender() {
      const that = this
      if (this.actionList.length === 0) return
      const arr = [0, 0]
      let height = 0

      that.$nextTick(() => {
        if (!that.$refs.item) { return }
        that.$refs.item.forEach((item, index) => {
          if (arr[0] > arr[1]) {
            that.$set(that.actionList[index], '_top', arr[1])
            that.$set(that.actionList[index], '_left', item.offsetWidth + 20)
            arr[1] += item.offsetHeight + 10
            height = arr[1]
          } else {
            that.$set(that.actionList[index], '_top', arr[0])
            that.$set(that.actionList[index], '_left', 0)
            arr[0] += item.offsetHeight + 10
            height = arr[0]
          }
        })

        that.boxHeight = height
      })
    },
    onCreate() {
      this.$refs.actionDetail.getTags(this.userId, this.deptId)
      this.$refs.actionDetail.open({
        column_id: this.columnsId,
        special_id: this.specialId,
        title: '',
        content: '',
        images: [],
        media_keys: [],
      }, this.columnName)
    },
    onUpdate(item) {
      var labels = []
      item.labels.forEach(element => {
        labels.push(element.id)
      })
      this.$refs.actionDetail.getTags(this.userId, this.deptId)
      this.$refs.actionDetail.open({
        id: item.id,
        title: item.title,
        special_id: item.special_id,
        content: item.detail.detail,
        column_id: item.column_id,
        images: item.images,
        media_keys: item.media,
        parent_id:item.parent_id,
        labels: labels,
        extra: item.extra || [],
      }, this.columnName)
    },
    onDelete(item) {
      this.$confirm('确定删除当前项吗？', '提示', { type: 'warning' })
        .then(() => {
          return deleteSpecialAction(item.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.onRefreshList()
        })
        .catch(() => {})
    },
    getActionFormat(id, list) {
      if (!id || !list) return 'primary'
      return list.findIndex(item => item.id === id) > -1 ? 'primary' : 'danger'
    },
    onFabulous(item) {
      updateActionFabulousUsers(item.id).then(() => {
        this.handlerGetFabulous(item)
        this.handlerGetUsers(item)
      })
    },
    onOpenComment(item) {
      this.$refs.comment.open(item.id)
    },
  },
}
</script>

<style scoped="scoped" lang="scss">
.praise-icon {
  width: 13px;
  height: 13px;
  margin-right: 8px;
  vertical-align: middle;
}

.content-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;

  .title {
    flex: 1;
    min-width: 0;
    margin-right: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.content-body {
  padding: 0 20px 10px;
  font-size: 16px;
  color: #666;
  word-break: break-all;
}

.content-time {
  display: inline-flex;
  padding: 10px 20px;
  font-size: 14px;
  color: #999;
  word-break: break-all;
}

.info-row {
  display: inline-flex;
  padding: 5px 20px;
  line-height: 30px;

  .info-label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #444;
  }

  .info-body {
    flex: 1;
  }
}

.list {
  margin-top: 6px;

  .item-box {
    position: relative;

    .item-position {
      position: absolute;
      width: 50%;
      padding: 0 10px;
    }

    .item {
      padding: 20px;

      .header {
        // display: flex;
        // justify-content: flex-end;

        .title {
          flex: 1;
          margin-bottom: 8px;
        }

        .time {
          display: inline;
          padding: 5px 15px;
          font-size: 14px;
          line-height: 14px;
          color: #000;
          background: #f7f8f9;
          border-radius: 4px;
        }
      }

      .info {
        padding: 15px;
        margin-top: 15px;
        background: rgba($color: #dfe3e8, $alpha: 0.26);
        border-radius: 10px;

        .content {
          padding: 5px 0;
          font-size: 16px;
          line-height: 24px;
          color: #2b2d42;
          // font-weight: bold;
          // text-indent: 2em;
        }

        .footer {
          display: flex;
          align-items: center;
          height: 30px;

          .user {
            flex: 1;
            min-width: 0;
            font-size: 16px;
            font-weight: 400;
            color: #747e8f;
            word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
